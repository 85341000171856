<template>
  <div class="absolute inset-0">
    <img
      v-if="layer.render"
      :src="layerSource"
      :alt="layer.name"
      class="w-full h-full object-contain"
    />
  </div>
</template>

<script>
export default {
  name: 'LayerDisplay',
  components: {},
  props: {
    layer: Object,
    ts: Number,
  },
  computed: {
    layerSource() {
      const uri = (
        this.layer.hex
          ? `/api/layer/${this.layer.name}?hex=${this.layer.hex}&ts=${this.ts}`
          : `/api/layers/${this.layer.name}.png?ts=${this.layer.ts}`
      );
      return uri;
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
